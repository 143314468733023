import React, { useEffect, useState } from 'react'
import { useP } from '../../../services/i18n'
export type typeNewTicketType = "DATA" | "VOIX" | "HEBERGEMENT" | null
import { useLazyQuery } from '@apollo/client'
import { GET_SITES } from './queries'
import InputSelect from '../../../Components/Form/InputSelect/InputSelect'

type InputSelectSiteType = {
  compteId: number | null,
  onChange: any
  errors: any[],
  label: string,
}


export default function InputSelectSite({ compteId, onChange, errors = [], label, ...field }: InputSelectSiteType) {

  const p = useP();

  const [defaultOptions, setDefaultOptions] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const opt = await promiseOptionsSite("")
      setDefaultOptions(opt)
    }
    fetchData();
  }, [compteId])

  const [getSites] = useLazyQuery(GET_SITES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const promiseOptionsSite = async (filter: string) => {

    const filterSite = filter ? [{
      column: "nom",
      operator: "LIKE",
      value: `%${filter}%`,
      or: [
        {
          column: "numeroVoie",
          operator: "LIKE",
          value: `%${filter}%`,
        },
        {
          column: "libelleVoie",
          operator: "LIKE",
          value: `%${filter}%`,
        },
        {
          column: "codePostal",
          operator: "LIKE",
          value: `%${filter}%`,
        },
        {
          column: "commune",
          operator: "LIKE",
          value: `%${filter}%`,
        },
      ]
    }] : []

    const filterCompte = compteId ? [{
      column: "id_compte",
      value: compteId?.toString()
    }] : []

    return getSites({
      variables: {
        filters: [...filterSite, ...filterCompte],
        sorters: [
          {
            column: "nom",
            order: "ASC"
          },
          {
            column: "numeroVoie",
            order: "ASC"
          },
          {
            column: "libelleVoie",
            order: "ASC"
          },
          {
            column: "codePostal",
            order: "ASC"
          },
          {
            column: "commune",
            order: "ASC"
          }],
      }
    }).then((result) => {
      const { data } = result;

      const display = data.sites.map(s => ({
        value: s.id,
        label: `${s.nom || ''} - ${s.numeroVoie || ''} ${s.libelleVoie || ''} ${s.codePostal || ''} ${s.commune || ''}`
      }));

      display.unshift({ value: "", label: p.t('support.modaleCreation.siteDefault') })

      return display;
    })
  }

  return <InputSelect
    errors={errors}
    promiseOptions={promiseOptionsSite}
    isMulti={true}
    defaultOptions={defaultOptions}
    placeholder={`${p.t('support.modaleCreation.sitePlaceHolder')}`}
    label={label}
    menuPlacement="top"
    {...field}
    onChange={onChange}
  />

}
